import PropTypes from 'prop-types';
import React, { Component } from "react";
import cx from "classnames";
import tc from "./TC.scss";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { registerClass as registerClassAPI } from "./../../../core/api/Vessel";
import { CheckBox as Cb } from "./../../Monitor/Edit/VesselEdit";
import ContentAdd from "material-ui/svg-icons/content/add";
import Chip from "material-ui/Chip";
import NarrowAutocomplete from "../../NewInputs/NarrowAutocomplete";
import { connect } from "react-redux";
import { v4 as uuidv4 } from 'uuid';
import { MenuItem } from 'material-ui';
export class RegisterClass extends Component {
  static contextTypes = {
    showMessage: PropTypes.func
  };

  /**
   * @typedef {{
   *   excluded: boolean
   *   iacsRegister: boolean
   *   registerClass?: { _id: string }
   * }} Value
   */

  /**
   * @param {{
   *   // value state in the parent component
   *   registerClasses?: Value[]
   *   // is called when clicking on (+) button
   *   handleChange(key: string, e: null, val: Value[]): void
   *   // the parent component can capture a function to apply the selected value
   *   captureSaveHandler(saveFn: () => { key: string, value: Value[] }): void
   *   // prefix field name
   *   prefix?: string
   *   // true by default, set to false to set _id to null for "Any" class
   *   useUuidForAny?: boolean
   *   // false by default, it does not make sense but changing that would require re-testing old features
   *   allowAnyOnlyIfThereIsNothingElse?: boolean
   *   chipStyle?: React.CSSProperties
   * }} props
   */
  constructor(props) {
    super(props);

    this.state = {
      excluded: false,
      iacsRegister: false,
      registerClass: ""
    };

    if (props.captureSaveHandler) {
      props.captureSaveHandler(() => {
        const value = this.addCountry({ ignoreAny: true, ignoreDuplicate: true })
        return { key: 'registerClasses', value }
      })
    }
  }

  handleRegisterClassChange = val => {
    let stateToUpdate = {
      ...this.state
    };

    stateToUpdate.registerClass = val;

    if (val.hasOwnProperty("iacsRegister")) {
      stateToUpdate.iacsRegister = val.iacsRegister;
    }

    this.setState(stateToUpdate);
  };

  handleChange = (name, ev, val) => {
    this.setState({
      [name]: val
    });
  };

  addCountry = ({ ignoreAny = false, ignoreDuplicate = false } = {}) => {
    const { registerClass, iacsRegister, excluded } = this.state;
    const { registerClasses = [] } = this.props;
    const isObj = typeof registerClass === "object";

    if (!registerClass) {
      if (ignoreAny) {
        return registerClasses
      }

      if (this.props.allowAnyOnlyIfThereIsNothingElse && !registerClass && registerClasses.length) {
        return registerClasses
      }
    }

    const isAnyIacs = !registerClass && iacsRegister;
    const isAny = !registerClass && !iacsRegister;
    if (isObj || isAny || isAnyIacs) {
      const alreadyExists = registerClasses.find(rClass =>
        isAny
          ? !rClass.registerClass && !rClass.iacsRegister
          : isAnyIacs
            ? !rClass.registerClass && rClass.iacsRegister
            : rClass.registerClass?._id === registerClass._id
      );

      if (alreadyExists) {
        if (!ignoreDuplicate) {
          this.props.dispatch({
            type: "MESSAGE",
            payload: {
              title: "Error",
              message: `${
                registerClass.name ? registerClass.name : "Any"
              } ${iacsRegister ? " (IACS)" : ""} is already added.`,
              level: "error",
              _id: new Date().getMilliseconds(),
              autoDismiss: 5
            }
          });
        }
      } else {
        this.setState(state => ({
          ...state,
          registerClass: "",
          iacsRegister: false
        }));

        const newRegisterClasses = [
          ...registerClasses,
          {
            excluded,
            iacsRegister,
            registerClass: registerClass ? registerClass : null,
            _id: registerClass._id || (this.props.useUuidForAny === false ? null : uuidv4())
          }
        ];
        this.changeParent(newRegisterClasses);

        return newRegisterClasses
      }
    }

    return registerClasses
  };

  changeParent = val =>
    this.props.handleChange && this.props.handleChange("registerClasses", null, val);

  delete = id => {
    const { registerClasses } = this.props;
    const newRegisterClasses = registerClasses.filter(
      registerClass => registerClass._id !== id
    );

    this.changeParent(newRegisterClasses);
  };

  renderAutocompleteItem = item => {
    return {
      text: item.name,
      _value: item,
      value: <MenuItem style={{fontSize: '13px'}} primaryText={item.name} />,
    };
  }

  filterIfIACS = data => this.state.iacsRegister ? data.filter(item => item.iacsRegister).map(this.renderAutocompleteItem) : data.map(this.renderAutocompleteItem)

  render() {
    const { excluded, iacsRegister, registerClass } = this.state;
    const { prefix = "vesselRequirements", registerClasses = [], chipStyle } = this.props;

    return (
      <div className={tc.col}>
        <div className={cx(tc.row, tc.register_row)} style={{ alignItems: "center" }}>
          <div>
            <NarrowAutocomplete
              renderer={this.filterIfIACS}
              floatingLabelText="Register Class"
              name={`${prefix}[registerClasses][${registerClasses.length}][registerClass]`}
              validations="isObj"
              validationError="Please, select register class"
              validationErrors={{
                isObj: "Please, select register class"
              }}
              onNewRequest={this.handleRegisterClassChange}
              onUpdateInput={this.handleRegisterClassChange}
              smallFont={true}
              value={registerClass}
              maxHeight={271}
              api={registerClassAPI}
              fullWidth
            />
          </div>
          <div>
            <Cb
              className={tc.checkbox}
              checked={iacsRegister}
              name={`${prefix}[registerClasses][${registerClasses.length}][iacsRegister]`}
              label="IACS register"
              disabled={typeof registerClass === "object"}
              onCheck={this.handleChange.bind(this, "iacsRegister")}
            />
          </div>
         <div>
           <Cb
             className={cx(tc.checkbox, tc.col_cb)}
             checked={excluded}
             name={`${prefix}[registerClasses][${registerClasses.length}][excluded]`}
             label="Excluded"
             onCheck={this.handleChange.bind(this, "excluded")}
           />
           <div
             className={cx(tc.add_btn, tc.col_add_btn)}
             style={{
               alignItems: "flex-end"
             }}
             onClick={this.addCountry}
           >
             <ContentAdd color="#285596" style={{ padding: "2px" }} />
           </div>
         </div>

        </div>
        <div className={tc.row_start_wrap}>
          {registerClasses?.length
            ? registerClasses.map(registerClass => (
                <Chip
                  key={registerClass.registerClass?._id ?? registerClass._id}
                  onRequestDelete={this.delete.bind(this, registerClass._id)}
                  backgroundColor={
                    registerClass.excluded
                      ? "var(--chips-excluded)"
                      : "var(--chips-included)"
                  }
                  style={{ margin: "16px 8px 0px 0px", ...chipStyle }}
                  className={tc.chip}
                >{`${registerClass.excluded ? "- " : ""}${
                  registerClass.registerClass?.name
                    ? registerClass.registerClass.name
                    : "Any"
                } ${registerClass.iacsRegister ? `(IACS)` : ""}`}</Chip>
              ))
            : null}
        </div>
      </div>
    );
  }
}

export default connect()(withStyles(tc)(RegisterClass));
